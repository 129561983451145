@import url(https://fonts.googleapis.com/css2?family=Exo:wght@900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333;
  color: #ddd;
  margin: 0;
  padding: 0;
}

.link,
.link a {
  text-decoration: none;
}

#logo-wrapper {
  width: 80%;
  margin: 0 10%;
  text-decoration: none;
}

#logo {
  text-align: left;
  position: relative;
  font-family: 'Exo';
  font-size: 3em;
  margin: 0 auto;
  -webkit-transform: skew(-15deg);
          transform: skew(-15deg);
  letter-spacing: 0.03em;
}

#logo span:first-child {
  display: block;
  text-shadow: 0 0 0.1em #165ff3, 0 0 0.3em black, 0 0 5em #165ff3;
  -webkit-text-stroke: 0.06em rgba(black, 0.5);
}

#logo span:last-child {
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(#032d50 25%, #00a1ef 35%, white 50%, #20125f 50%, #8313e7 55%, #ff61af 75%);
  -webkit-text-stroke: 0.01em #94a0b9;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#app {
  text-align: left;
  background-image: url(/static/media/background.d94b7648.jpg);
  background-color: #212529;
  background-size: cover;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
}

#container-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: -webkit-max-content;
  grid-template-rows: max-content;
  grid-template-areas:
    "left-sidebar header header"
    "left-sidebar main main";
}

#header-block-grid {
  grid-area: header;
  text-align: right;
  margin: 1em;
}

#left-sidebar-grid {
  grid-area: left-sidebar;
  padding: .5em 0 0 0;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  height: 100vh;
  max-width: 30vw;
  overflow: hidden;
}

#main-block-grid {
  grid-area: main;
  margin: 0 0 0 2em;
}

#right-sidebar-grid {
  grid-area: right-sidebar;
}


#container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}

#left-sidebar {
  height: 100vh;
  flex-basis: 20rem;
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  overflow: hidden;
}

#main-block {
  flex-basis: 0;
  flex-grow: 999;
  min-inline-size: 50%;
  text-align: center;
  vertical-align: top;
}

#intro-block {
  margin: auto;
  width: 70vw;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 1.3em;
  line-height: 2.5em;
}

#center-block {
  margin: auto;
  width: 70vw;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

#header-block {
  width: 100%;
  text-align: right;
  padding: 1em;
}


#playlist-search-input {
  font-size: 1em;
}

.searchbar {
  width: 80%;
  margin: 0 10%;
}

#show-tracks {}

.tracks-header {
  background-color: rgba(0, 255, 255, 0.1);
  display: block;
  width: 100%;
  /* max-width: 800px; */
  /* margin: 2em 10% 0 10%; */
  margin: 2em 0 0 0;
  padding: .6em .7em .4em .7em;
  font-weight: bold;
  font-size: 1.2em;
}

.tracks-subheader {
  font-weight: normal;
  font-size: 0.6em;
  line-height: 1.2em;
  font-style: italic;
}

.tracks {
  display: block;
  width: 100%;
  /* max-width: 800px; */
  /* margin: 0 10% 0 10%; */
  margin: 0;
  background-color: rgba(0, 0, 0, 0.5);
  max-height: 20em;
  overflow-y: scroll;
  overflow-x: hidden;
  cursor: pointer;
}

.track-row {
  padding: .2em 0;
  display: block;
}

#featured-playlists {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0 0 2em 0;
}

.sidebar-playlist {
  max-width: 800px;
  margin: 1em 2%;
  background-color: rgba(0, 255, 255, 0.2);
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  max-height: calc(100vh - var(--offset) * 2);
}

#my-playlists {}

#search-playlists {}

.playlist-header {
  background-color: rgba(255, 0, 255, 0.1);
  display: block;
  width: 100%;
  margin: 1em 0 0 0;
  padding: .6em .7em .4em .7em;
  font-weight: bold;
  font-size: 1.2em;
}

.fail-playlist-row {
  padding: .2em 0;
  display: block;
  background-color: #600;
}

.playlist-row {
  padding: .3em 0;
  display: block;
  text-align: left;
}

.playlist-row:hover {
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
}

.playlist-row-active,
.playlist-row-active:hover {
  padding: .3em 0;
  display: block;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.45) !important;
  color: white;
}

.playlist-track-row {
  padding: .3em 0;
  display: flex;
  text-align: left;
}

.fail-playlist-track-row {
  padding: .3em 0;
  display: flex;
  text-align: left;
  background-color: #600;
}


.playlist-track-row:hover {
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
}

.playlist-track-row-active,
.playlist-track-row-active:hover {
  padding: .3em 0;
  display: flex;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.45) !important;
  color: white;
}

.playlist-number {
  width: 40px !important;
  display: inline-block;
}

.playlist-album {
  display: inline-block;
}

.playlist-album img {
  display: inline-block;
  width: 60px;
  height: 60px;
}

.playlist-trackinfo {
  display: inline-block;
  padding-left: 1em
}

.playlist-title {
  font-weight: normal;
}

.playlist-artist {
  margin-top: .2em;
  font-size: smaller;
}

.carousel-inner img {
  margin: auto;
  max-height: 300px;
  /* max-width: 300px; */
}

.dark-button {
  background-color: rgba(0, 0, 0);
}

.dark-button:hover {
  background-color: #244;
}

#progress-status {
  font-size: 0.8em;
  color: white;
  font-style: italic;
  margin: auto 1.5em;
}

#show-featured-link {
  display: block;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  padding: 1em;
}
